function initDataTable(options){

   var table = $(options.table).dataTable({
      //scrollY: 440,
      //fixedColumns: true,
      "pageLength": options.paginateLimit ? options.paginateLimit : 9,
      "bJQueryUI": true,
      "bPaginate": options.paginateInit > 9 ? true : false,
      "bInfo": options.paginateInit > 9 ? true : false,
      "oLanguage": {
          "sProcessing": "Aguarde...",
          "sLengthMenu": "Mostrar _MENU_ registos",
          "sZeroRecords": "Não foram encontrados resultados",
          //"sInfo": "Mostrar de _START_ até _END_ de _TOTAL_ registos",
          //"sInfoEmpty": "Mostrar de 0 até 0 de 0 registos",
          "sInfo": "_START_ (_TOTAL_)",
          "sInfoEmpty": "0 (0)",
          "sInfoFiltered": "",
          "sInfoPostFix": "",
          "sSearch": "Pesquisar:",
          "sUrl": "",
          "oPaginate": {
              "sFirst": "Primeiro",
              "sPrevious": "«",
              "sNext": "»",
              "sLast": "Último"
          }
      }
  });

  var hidenFilters = document.querySelectorAll(".dataTables_wrapper .col-md-6")
  hidenFilters.forEach(elem => {
     elem.remove()
  })

  $(".isTblSearchInput").on('keyup', function() {
      table.fnFilter(this.value);
  });

  $(".isMenuLength").on('change', function(){
      var oSettings = table.fnSettings();
      oSettings._iDisplayLength = $(".isMenuLength").val();
      table.fnDraw();
      //alert(length);
  });
}
