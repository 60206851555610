function initSummerNote(){
   $('.summernoteFull').summernote({
      //placeholder: 'Digite aqui o set texto',
      //placeholder: 'Digite aqui o set texto',
      callbacks: {
          onPaste: function (e) {
              var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');

              e.preventDefault();

              // Firefox fix
              setTimeout(function () {
                  document.execCommand('insertText', false, bufferText);
              }, 10);
          }
      },

          tabsize: 2,
          height: 150,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['link', 'picture']],
        ['view', ['fullscreen']]
        //['view', ['fullscreen', 'codeview', 'help']]
      ]
    });

    $('.summernoteShort').summernote({
      //placeholder: 'Digite aqui o set texto',
      callbacks: {
          onPaste: function (e) {
              var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');

              e.preventDefault();

              // Firefox fix
              setTimeout(function () {
                  document.execCommand('insertText', false, bufferText);
              }, 10);
          }
      },

      height: 120,
      tabsize:50,
      toolbar: [
         ['style', ['style']],
         ['font', ['bold', 'italic', 'underline', 'clear']],
         ['color', ['color']],
         ['para', ['ul', 'ol', 'paragraph']],
         ['insert', ['link']],
      ]
    });

    $('.summernoteSimple').summernote({
      //placeholder: 'Digite aqui o set texto',
      callbacks: {
          onPaste: function (e) {
              var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');

              e.preventDefault();

              // Firefox fix
              setTimeout(function () {
                  document.execCommand('insertText', false, bufferText);
              }, 10);
          }
      },

      height: 250,
      tabsize:50,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'clear']],
        ['color', ['color']]
      ]
    });

}
